import query from "@/store/query";

export const setCookies = (cookies: Record<string, string>) => {
  // 布局未开启情况下，不允许写入cookie
  if(query.noLayout) return;
  if (!cookies) return
    Object.entries(cookies).forEach(([key, value]) => {
      // 如果值是对象，序列化它
      if (typeof value === 'object' && value !== null) {
        value = JSON.stringify(value);
      }
      
      const { hostname } = location;
      document.cookie = `${key}=${value || ""}; path=/; domain=.${hostname}`;
    });
}

// 删除cookie
export function deleteCookie(name) {
  const { hostname } = location;
  document.cookie = name + `=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.${hostname}`;
}

// 清除cookie 兼容老的逻辑
export function clearCookie(){
  const names = ['FUWEEX_ACCESS_TOKEN', 'Gaia_Theme', 'Gaia_language', 'Gaia_username', 'environment', 'language', 'tenant', 'theme'];
  const { hostname } = location;
  const domain = hostname.split('.').slice(-2).join('.')
  for (const name of names) {
    document.cookie = name + `=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.${domain}`;
  }
}

// 获取cookie
export const getCookie = (cookieName) => {
  if(cookieName == 'tenant'){
    if(import.meta.env.VITE_SKIP_TENANT == "true") return import.meta.env.VITE_TENANT;
  }
    var allCookies = document.cookie;
    if (allCookies === "")
      return null;
    var cookieArray = allCookies.split("; ");
    for (var i = 0; i < cookieArray.length; i++) {
      var cookie = cookieArray[i];
      var separatorIndex = cookie.indexOf("=");
      var name = cookie.substring(0, separatorIndex);
      var value = cookie.substring(separatorIndex + 1);
      if (name === cookieName) {
        return decodeURIComponent(value);
      }
    }
    return null;
}