import { getAllQueryParam } from "@/utils/url";


const { noLayout } = getAllQueryParam();
class Query {
    
    noLayout = noLayout == 'true';  // 隐藏布局

}


export default new Query();