import { useI18n } from '@/hooks/web/useI18n'
import * as NotifyMessageApi from '@/api/system/notify/message'
import tenant from '@/store/tenant'
import { defineStore } from 'pinia'
import dayjs from 'dayjs'
import { useRouter } from 'vue-router'

/**
 * 通知信息
 */
interface NotificationItem {
    // 总通知条数
    count: number;
}

interface pageInfoVo {
    label?: string,
    labelKey: string,
    code: string,
    icon: string,
    hasClean: boolean
}


interface MessageBellState {
    // 应用未读总条数
    data: Record<string, NotificationItem>;
    // 消息未读条数
    taskCount: number;
    // 待办未读条数
    messageCount: number;
    // 公告未读数
    announceCount: number;
    // 文件中心待确认数量
    fileCount: number;
    drawerVisible: boolean; // 抽屉显示
    pageList: Array<T>; //渲染的tabs
    currentPage: string; // 'overview',
    currentPageInfo: pageInfoVo; // 
    currentShowAllStatus: boolean; // checkbox-展示全部
}

const { t } = useI18n()


const defaultPageList = [
    {
        labelKey: 'sys.notification.message', //'消息',
        code: 'notice',
        icon: '#icon-msgbell-xiaoxi',
        countField: 'messageCount',
        tempalteLangKey: 'sys.message.unread.tips',
        permission: 'portalMyMessageSettingFlag',
        hasClean: true
    },
    {
        labelKey: 'sys.notification.todo', //'待办事项',
        code: 'todoTask',
        icon: '#icon-msgbell-daibanshixiang',
        countField: 'taskCount',
        tempalteLangKey: 'sys.todo.unread.tips',
        permission: 'portalMyTodoSettingFlag',
        hasClean: false
    },
    {
        labelKey: 'sys.announce', //'公告',
        code: 'announce',
        icon: '#icon-msgbell-gonggao',
        countField: 'announceCount',
        tempalteLangKey: 'sys.annoincements.unread.tips',
        permission: 'portalMyNoticeSettingFlag',
        hasClean: true
    },
    {
        labelKey: 'fileProcessCenter.fileCenter', //'文件处理中心',
        code: 'fileCenter',
        icon: '#icon-msgbell-wenjianchulizhongxin',
        countField: 'fileCount',
        tempalteLangKey: 'sys.files.not.processed',
        permission: 'isPortalImportCenter',
        hasClean: false
    }
]



export const userMsgBell = defineStore('messageBell', {

    state(): MessageBellState {
        return {
            drawerVisible: false,
            pageList: [],
            currentPage: 'overview',
            currentPageInfo: {
                labelKey: '',
                code: '',
                icon: '',
                hasClean: false
            },
            currentShowAllStatus: false,
            data: {
                myMessage: { count: 0 }
            },
            taskCount: 0,
            messageCount: 0,
            announceCount: 0,
            fileCount: 0,
        }
    },

    getters: {
        count(state) {
            return (key?: string) => {
                if (key) return state[key]
                return 0
            }
        },
        allCount(state) {
            let count = state.taskCount + state.messageCount + state.announceCount + state.fileCount
            for (const key in this.data) {
                count += this.data[key].count
            }
            return count
        }
    },
    actions: {
        // 根据portalUseTodoMsg渲染当前所需要的tabs
        initPageList() {
            console.log('123123', t('sys.notification'))
            console.log('defaultPageList==', defaultPageList)
            const list = defaultPageList.filter(item => tenant.info[item.permission])
            this.pageList = list
        },
        toolgeVisible(show: boolean) {
            if (!show) {
                this.currentPage = 'overview'
                this.currentPageInfo = {} as pageInfoVo
            }
            this.drawerVisible = show
        },
        handleViews(item: any) {
            console.log('in store item==', item)
            this.currentPage = item.code
            this.currentPageInfo = { ...item }
        },
        handleMore(router) {
            const { code } = this.currentPageInfo;
            if (!tenant.info.portalUseTodoMsg) {
                // 新版
                if (code == 'notice') {
                    router.push({ path: '/portal/message' })
                }
                if (code == 'todoTask') {
                    router.push({ path: '/portal/approval' })
                }
            }
            if (tenant.info.portalUseTodoMsg) {
                // 老版
                if (code == 'notice') {
                    router.push({ path: '/portal/message', query: { msgType: 1 } })
                }
                if (code == 'todoTask') {
                    router.push({ path: '/portal/message', query: { msgType: 2 } })
                }
            }
            if (code == 'announce') {
                router.push({ path: '/portal/announce' })
            }
            if (code == 'fileCenter') {
                router.push({ path: '/portal/import-center' })
            }
            this.drawerVisible = false
        },
        async clearAllCount(code: string) {
            console.log('clearAllCount pageCode', code)
            if (!tenant.info.portalUseTodoMsg) {
                // 新版
                if (code == 'notice') {
                    // 不区分msgType
                    await NotifyMessageApi.updateAllNotifyMessageRead()
                    this.getMessageCount()
                }
            }
            if (tenant.info.portalUseTodoMsg) {
                // 老版
                if (code == 'notice') {
                    // msgType:1
                    await NotifyMessageApi.updateAllNotifyMessageRead({ msgType: 1 })
                    this.getMessageCount()
                }
                if (code == 'todoTask') {
                    // msgType:2
                    await NotifyMessageApi.updateAllNotifyMessageRead({ msgType: 2 })
                    this.getToTaskCount()
                }
            }
            if (code == 'announce') {
                await NotifyMessageApi.updateAnnounceReadAll()
                this.getAnnounceCount()
            }
        },
        async downloadFile(item) {
            const url = item.fileUrl
            const fileKey = item.fileKey
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error("Failed to download file");
            }
            const blob = await response.blob();
            const a = document.createElement("a");
            const objectUrl = URL.createObjectURL(blob);
            a.href = objectUrl;
            a.download = fileKey || url.split("/").pop();
            document.body.appendChild(a);
            a.click();
            URL.revokeObjectURL(objectUrl); // 释放资源
            document.body.removeChild(a);
        },
        groupByDay(list: any) {
            const { t } = useI18n()
            const groupedData = list.reduce((acc, item) => {
                const createTime = dayjs(item.createTime);
                const today = dayjs().startOf('day');
                const yesterday = dayjs().subtract(1, 'day').startOf('day');

                let label = '';
                if (createTime.isSame(today, 'day')) {
                    label = t('form.today');
                } else if (createTime.isSame(yesterday, 'day')) {
                    label = t('form.yesterday');
                } else {
                    label = t('form.earlier');
                }

                const existingGroup = acc.find(group => group.label === label);
                if (existingGroup) {
                    existingGroup.list.push(item);
                } else {
                    acc.push({ label, list: [item] });
                }

                return acc;
            }, []);
            return groupedData
        },
        // 获取我的消息未读数量
        async getMessageCount() {
            if (tenant.info.portalMyMessageSettingFlag) {
                this.messageCount = await NotifyMessageApi.getUnreadNotifyMessageCount({ isUseToDoMsg: tenant.info.portalUseTodoMsg ? 1 : 0 })
            } else {
                this.messageCount = 0
            }
        },
        // 获取未处理待办条数
        async getToTaskCount() {
            if (tenant.info.portalMyTodoSettingFlag) {
                if (!tenant.info.portalUseTodoMsg) {
                    //新版 ,taskStatus，固定传1, （1-待我处理 2-我已处理）
                    this.taskCount = await NotifyMessageApi.countTodoTask({ taskStatus: 1 })
                } else {
                    //老版
                    this.taskCount = await NotifyMessageApi.getUnreaTodoTaskOld()
                }
            } else {
                this.taskCount = 0
            }
        },
        // 获取公告未读数量
        async getAnnounceCount() {
            if (tenant.info.portalMyNoticeSettingFlag) {
                this.announceCount = await NotifyMessageApi.getAnnounceUnread()
            } else {
                this.announceCount = 0
            }
        },
        // 获取文件中心待确认数量
        async getFileCount() {
            if (tenant.info.isPortalImportCenter) {
                this.fileCount = await NotifyMessageApi.getFileCenterUnconfirmV2({ openType: 1 })
            } else {
                this.fileCount = 0
            }
        },
        // 获取所有消息条数
        initCount() {
            return new Promise<void>(async(resolve)=>{
                await this.getMessageCount()
                await this.getToTaskCount()
                await this.getAnnounceCount()
                await this.getFileCount()
                resolve()
            })
        },
        async setMessageCount(messageCount: number) {
            if (tenant.info.portalMyMessageSettingFlag) {
                this.messageCount = messageCount
            }
        },
        async setTaskCount(taskCount: number) {
            if (tenant.info.portalMyTodoSettingFlag) {
                this.taskCount = taskCount
            }
        },
        async setAnnounceCount(announceCount: number) {
            if (tenant.info.portalMyNoticeSettingFlag) {
                this.announceCount = announceCount
            }
        },
        async setFileCount(fileCount: number) {
            if (tenant.info.isPortalImportCenter) {
                this.fileCount = fileCount
            }
        }
    }
})
