/** 获取 url 中 query string */
export function getQueryString(name: string): string | null {
    const reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`, 'i');
    let param;
    if (window.location.hash.split('?').length > 1) {
        param = window.location.hash.split('?')[1].match(reg);
    } else {
        param = window.location.search.substr(1).match(reg);
    }
    if (param !== null) {
        return decodeURIComponent(param[2]);
    }
    return null;
}


// 获取url所有参数
export const getAllQueryParam = (url?: string): Record<string, string> => {
    let path = url || window.location.href.replace('#/','&=');
    const result = Object.create(null);
    if (!path || path.indexOf('?') === -1) return result;
    const paths = path.split('?');
    paths.shift()
    for (const pathStr of paths) {
        const array = pathStr.split(/[&=]/);
        for (let i = 0; i < array.length; i += 2) {
            result[array[i]] = decodeURIComponent(array[i + 1]);
        }
    }
    return result;
}